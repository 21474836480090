















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { EState, IDropDown } from './NewFeedLayout.vue'

@Component
export default class PcButtonArea extends Vue {
  @Prop() private actionAreaState!: EState
  @Prop() private roleEditTopic!: boolean
  @Prop() private isSelectAnyItem!: boolean
  @Prop() private checkAll!: boolean
  @Prop() private sortSelected!: any
  @Prop() private sortOptions!: IDropDown[]
  @Prop() private topicLength!: number

  private checkAllClone: boolean = this.checkAll
  private isCommonTopic = !this.$route.params.groupId
  private eState = EState

  @Watch('checkAll')
  watchCheckAll() {
    this.checkAllClone = this.checkAll
  }

  handleMoveToUnclassified(isFromBin: boolean) {
    this.$emit('handleMoveToUnclassified', isFromBin)
  }

  openConfirmDeleteBin(isFromBin: boolean) {
    this.$emit('openConfirmDeleteBin', isFromBin)
  }

  handleSortChange(index: number) {
    this.$emit('handleSortChange', index)
  }

  handleCheckAll() {
    this.$emit('handleCheckAll', this.checkAllClone)
  }

  jumpToCreateTopicPage() {
    this.$emit('jumpToCreateTopicPage')
  }
}
