































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ModalAddFolder from '@/components/Modal/ModalAddFolder.vue'
import ModalMoveToFolder from '@/components/Modal/ModalMoveToFolder.vue'
import ModalEditFolder from '@/components/Modal/ModalEditFolder.vue'
import ListTopic from '@/views/Group/TopPage/NewFeed/ListTopic.vue'
import MobileButtonArea from '@/views/Group/TopPage/NewFeed/Layout/MobileButtonArea.vue'
import PcButtonArea from '@/views/Group/TopPage/NewFeed/Layout/PcButtonArea.vue'
import SidebarPc from '@/views/Group/TopPage/NewFeed/Layout/SidebarPc.vue'
import {
  EFolderItemCountType,
  EFolderType,
  IFolderItemCount,
  MoveToUnclsFrom,
  TopicCompact,
  TopicFolder
} from '@/models'
import CommonTopicService from '@/services/CommonTopicService'
import GroupTopicService from '@/services/GroupTopicService'
import GroupService from '@/services/GroupService'
import { EListSort } from '@/models/Setting/Enum'

import store from '@/store'
import Setting from '@/store/modules/Setting'
import { getModule } from 'vuex-module-decorators'
const SettingModule = getModule(Setting, store)

/**
 * f2-401
 * f2-701
 * f2-401b1
 * サークル掲示板TOP
 * 共通トピックTOP
 * サークル掲示板 - ゴミ箱
 */
@Component({
  components: {
    MobileButtonArea,
    PcButtonArea,
    SidebarPc,
    ListTopic,
    ModalAddFolder,
    ModalMoveToFolder,
    ModalEditFolder
  }
})
export default class NewFeedLayout extends Vue {
  private startLoading: boolean = true
  private isCommonTopic = !this.$route.params.groupId
  private roleEditTopic: boolean = false
  private title: string = this.$t('groups.new_feed.title') as string
  private sortSelected: any = {}
  private sortOptions: IDropDown[] = []
  private allFolder: TopicFolder[] = []
  private folderSelected: any = {
    text: `${this.$t('groups.all')}`,
    suffix: ' (0)'
  }
  private folderEdit: any = {}
  private folderOptions: IDropDown[] = []
  private topics: TopicCompactViewModel[] = []
  private checkAll: boolean = false
  private isSelectAnyItem: boolean = false
  private actionAreaState: EState = EState.ALL
  private modalMess: string = ''
  private isDeleteAllBin: boolean = false
  private eState = EState
  private page: number = 1
  private maxPage: number = 1
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private folderItemCount: IFolderItemCount[] = []
  private roleAdminGroup: boolean = false

  openEditFolderModal(index?: number) {
    this.folderEdit = index ? this.folderOptions[index] : this.folderSelected
    this.$bvModal.show('edit-folder')
  }

  created() {
    this.initSortDropdown()
    this.initFolder()
    this.handleRoleEditTopic()
  }

  @Watch('sortSelected')
  @Watch('folderSelected')
  init() {
    this.page = 1
    if (this.isCommonTopic) {
      SettingModule.CHANGE_SORT_COMMON_TOPIC(this.sortSelected.id)
      if (this.sortSelected.id !== undefined) {
        this.allCommonTopic()
        this.title = this.$t('groups.new_feed.title') as string
      }
    } else {
      SettingModule.CHANGE_SORT_GROUP_TOPIC(this.sortSelected.id)
      if (
        this.folderSelected.id !== undefined &&
        this.sortSelected.id !== undefined
      ) {
        this.checkAll = false
        this.isSelectAnyItem = false
        this.checkAll = false
        switch (this.folderSelected.id) {
          case 0:
            this.actionAreaState = EState.ALL
            this.allTopicNotDelete()
            this.title = this.$t('groups.new_feed.title') as string
            this.$router.replace({ query: { folder: 'all' } }).catch(() => {})
            break
          case 1:
            this.actionAreaState = EState.ALL
            this.allTopicUnclassified()
            this.title = this.$t('groups.new_feed.title') as string
            this.$router
              .replace({ query: { folder: 'unclassified' } })
              .catch(() => {})
            break
          case 2:
            this.actionAreaState = EState.BIN
            this.allTopicDeleted()
            this.title = this.$t('groups.bin') as string
            this.$router.replace({ query: { folder: 'bin' } }).catch(() => {})
            break
          case 3:
            this.actionAreaState = EState.DRAFT
            this.getAllDraftTopic()
            this.title = this.$t('groups.draft') as string
            this.$router.replace({ query: { folder: 'draft' } }).catch(() => {})
            break
          default:
            this.actionAreaState = EState.FOLDER
            this.allTopicByFolder()
            this.title = this.folderSelected.text
            this.$router
              .replace({
                query: {
                  folder: `${this.folderSelected.value.id}`
                }
              })
              .catch(() => {})
            break
        }
      }
    }
  }

  @Watch('$store.state.userInfo.user.group_role')
  handleRoleEditTopic() {
    if (this.isCommonTopic) {
      this.roleEditTopic = this.$store.state.userInfo.user.common_topic_role
    } else {
      this.roleEditTopic = this.$store.state.userInfo.user.group_role.role_edit_topic
    }
  }

  async getTopicFolderItemCount() {
    return GroupTopicService.getTopicFolderItemCount(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.folderItemCount = res.data
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error')
      })
  }

  /**
   * Call API for topics that not detele
   */
  allTopicNotDelete(loadMore?: boolean) {    
    GroupTopicService.getAllTopicNotDelete({
      group_id: this.$route.params.groupId,
      order_by: this.sortSelected.value.order_by,
      sorted_by: this.sortSelected.value.sorted_by,
      page: this.page,
      limit: this.limit
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.topics = [
              ...this.topics,
              ...res.data.data.map(
                (item: any) => new TopicCompactViewModel(item)
              )
            ]
          } else {
            this.topics = res.data.data.map(
              (item: any) => new TopicCompactViewModel(item)
            )
          }
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Call API for topics of unclassified
   */
  allTopicUnclassified(loadMore?: boolean) {
    GroupTopicService.getAllTopicUnclassified({
      group_id: this.$route.params.groupId,
      order_by: this.sortSelected.value.order_by,
      sorted_by: this.sortSelected.value.sorted_by,
      page: this.page,
      limit: this.limit
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.topics = [
              ...this.topics,
              ...res.data.data.map(
                (item: any) => new TopicCompactViewModel(item)
              )
            ]
          } else {
            this.topics = res.data.data.map(
              (item: any) => new TopicCompactViewModel(item)
            )
          }
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Call API for topics deleted
   */
  allTopicDeleted(loadMore?: boolean) {
    if (this.roleEditTopic) {
      GroupTopicService.getAllTopicDeleted({
        group_id: this.$route.params.groupId,
        order_by: this.sortSelected.value.order_by,
        sorted_by: this.sortSelected.value.sorted_by,
        page: this.page,
        limit: this.limit
      })
        .then(res => {
          if (res.status === 200) {
            if (loadMore) {
              this.topics = [
                ...this.topics,
                ...res.data.data.map(
                  (item: any) => new TopicCompactViewModel(item)
                )
              ]
            } else {
              this.topics = res.data.data.map(
                (item: any) => new TopicCompactViewModel(item)
              )
            }
            this.maxPage = res.data.last_page
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.$bvModal.show('modal-error')
        })
        .finally(() => {
          this.startLoading = false
        })
    }
  }

  /**
   * Call API for topics in future
   */
  getAllDraftTopic(loadMore?: boolean) {
    if (this.roleEditTopic) {
      GroupTopicService.getAllDraftTopic({
        group_id: this.$route.params.groupId,
        order_by: this.sortSelected.value.order_by,
        sorted_by: this.sortSelected.value.sorted_by,
        page: this.page,
        limit: this.limit
      })
        .then(res => {
          if (res.status === 200) {
            if (loadMore) {
              this.topics = [
                ...this.topics,
                ...res.data.data.map(
                  (item: any) => new TopicCompactViewModel(item)
                )
              ]
            } else {
              this.topics = res.data.data.map(
                (item: any) => new TopicCompactViewModel(item)
              )
            }
            this.maxPage = res.data.last_page
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.$bvModal.show('modal-error')
        })
        .finally(() => {
          this.startLoading = false
        })
    }
  }

  /**
   * Call API for topics by folder
   */
  allTopicByFolder(loadMore?: boolean) {
    if (!this.isCommonTopic) {
      GroupTopicService.getAllTopicByFolder({
        group_id: this.$route.params.groupId,
        order_by: this.sortSelected.value.order_by,
        sorted_by: this.sortSelected.value.sorted_by,
        page: this.page,
        limit: this.limit,
        folder_id: this.folderSelected.value.id
      })
        .then(res => {
          if (res.status === 200) {
            if (loadMore) {
              this.topics = [
                ...this.topics,
                ...res.data.data.map(
                  (item: any) => new TopicCompactViewModel(item)
                )
              ]
            } else {
              this.topics = res.data.data.map(
                (item: any) => new TopicCompactViewModel(item)
              )
            }
            this.maxPage = res.data.last_page
          }
        })
        .catch(err => {
          if (
            err.response.status === 404 &&
            err.response.data.message === 'folder_not_found'
          ) {
            this.modalMess = this.$t(
              'common.message.folder_not_exist'
            ) as string
            this.$bvModal.show('modal-error')
            this.initFolder()
          }
        })
        .finally(() => {
          this.startLoading = false
        })
    }
  }

  /**
   * Call API for common topics
   */
  allCommonTopic(loadMore?: boolean) {
    CommonTopicService.getAllCommonTopic({
      group_id: this.$route.params.groupId,
      order_by: this.sortSelected.value.order_by,
      sorted_by: this.sortSelected.value.sorted_by,
      page: this.page,
      limit: this.limit,
      unread_only: '0'
    })
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.topics = [
              ...this.topics,
              ...res.data.data.map(
                (item: any) => new TopicCompactViewModel(item)
              )
            ]
          } else {
            this.topics = res.data.data.map(
              (item: any) => new TopicCompactViewModel(item)
            )
          }
          this.maxPage = res.data.last_page
          this.roleAdminGroup = res.data.role_admin_group
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error')
      })
      .finally(() => {
        this.startLoading = false
      })
  }

  /**
   * Call API for loadmore
   */
  loadMore() {
    ++this.page
    this.checkAll = false
    if (this.isCommonTopic) {
      this.allCommonTopic(true)
    } else {
      switch (this.folderSelected.id) {
        case 0:
          this.allTopicNotDelete(true)
          break
        case 1:
          this.allTopicUnclassified(true)
          break
        case 2:
          this.allTopicDeleted(true)
          break
        default:
          this.allTopicByFolder(true)
          break
      }
    }
  }

  /**
   * Init sort dropdown
   */
  initSortDropdown() {
    const data = [
      {
        id: EListSort.UPDATE_DESC,
        value: {
          order_by: 'updated_at',
          sorted_by: 'desc'
        },
        text:
          this.$t('common.dropdown_sort.sort_date_time') +
          '' +
          this.$t('common.dropdown_sort.desc')
      },
      {
        id: EListSort.UPDATE_ASC,
        value: {
          order_by: 'updated_at',
          sorted_by: 'asc'
        },
        text:
          this.$t('common.dropdown_sort.sort_date_time') +
          '' +
          this.$t('common.dropdown_sort.asc')
      },
      {
        id: EListSort.TITLE_DESC,
        value: {
          order_by: 'title',
          sorted_by: 'desc'
        },
        text:
          this.$t('common.dropdown_sort.sort_title_name') +
          '' +
          this.$t('common.dropdown_sort.desc')
      },
      {
        id: EListSort.TITLE_ASC,
        value: {
          order_by: 'title',
          sorted_by: 'asc'
        },
        text:
          this.$t('common.dropdown_sort.sort_title_name') +
          '' +
          this.$t('common.dropdown_sort.asc')
      }
    ]
    this.sortOptions = data
    if (this.isCommonTopic) {
      this.sortSelected = data[this.$store.state.setting.user_sort.common_topic]
    } else {
      this.sortSelected = data[this.$store.state.setting.user_sort.group_topic]
    }
  }

  /**
   * Get count item for each folder
   */
  getCount(type: EFolderItemCountType, folderId?: number) {
    const item = this.folderItemCount.find(
      item =>
        item.type === type && (folderId ? item.folder_id === folderId : true)
    )
    return item && item.count ? item.count : 0
  }

  /**
   * Init folder dropdown
   */
  async initFolder() {
    if (!this.isCommonTopic) {
      await this.getTopicFolderItemCount()
      GroupTopicService.getAllFolder(this.$route.params.groupId)
        .then(res => {
          if (res.status === 200) {
            this.allFolder = res.data
            let defaultOptions = [
              {
                id: 0,
                value: 0,
                text: `${this.$t('groups.all')}`,
                suffix: ` (${this.getCount(EFolderItemCountType.ALL)})`
              },
              {
                id: 1,
                value: 1,
                text: `${this.$t('groups.unclassified')}`,
                suffix: ` (${this.getCount(EFolderItemCountType.UNCLASSIFIED)})`
              }
            ]

            //add bin if current user is have role
            if (this.roleEditTopic) {
              defaultOptions.push({
                id: 2,
                value: 2,
                text: `${this.$t('groups.bin')}`,
                suffix: ` (${this.getCount(EFolderItemCountType.IN_BIN)})`
              })
              defaultOptions.push({
                id: 3,
                value: 3,
                text: `${this.$t('groups.draft')}`,
                suffix: ` (${this.getCount(EFolderItemCountType.DRAFT)})`
              })
            }

            const folders = res.data.map((item: TopicFolder, index: number) => {
              return {
                id: index + 4,
                value: item,
                text: `${item.name}`,
                suffix: ` (${this.getCount(
                  EFolderItemCountType.FOLDER,
                  item.id
                )})`
              }
            })
            const mergeDatas = [...defaultOptions, ...folders]
            this.folderOptions = mergeDatas
            this.handleFolderSelected()
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.$bvModal.show('modal-error')
        })
    }
  }

  /**
   * Hanlde folder selected by query folder
   */
  handleFolderSelected() {
    if (this.$route.query.folder) {
      switch (this.$route.query.folder) {
        case 'all':
          this.folderSelected = this.folderOptions[0]
          break
        case 'unclassified':
          this.folderSelected = this.folderOptions[1]
          break
        case 'bin':
          this.folderSelected = this.folderOptions[2]
          break
        case 'draft':
          this.folderSelected = this.folderOptions[3]
          break
        default:
          //get current selected folder id
          const folderSelectedId = Number(this.$route.query.folder)

          //if current selected folder id exist
          if (folderSelectedId) {
            const newFolderSelected = this.folderOptions.find(
              item => item.value.id === folderSelectedId
            )
            if (newFolderSelected) {
              this.folderSelected = newFolderSelected
            } else {
              this.folderSelected = this.folderOptions[0]
            }

            //if current selected folder id not exist
          } else {
            this.folderSelected = this.folderSelected.value
              ? this.folderOptions[this.folderSelected.value]
              : this.folderOptions[0]
          }
      }
    } else {
      this.folderSelected = this.folderOptions[0]
      this.$router.replace({ query: { folder: 'all' } }).catch(() => {})
    }
  }

  /**
   * Handle checkbox check all
   */
  handleCheckAll(checkAll: boolean) {
    this.checkAll = checkAll
    if (this.checkAll) {
      this.topics.forEach(item => (item.checked = true))
      this.hanldeSelectAnyItem(true)
    } else {
      this.topics.forEach(item => (item.checked = false))
      this.hanldeSelectAnyItem(false)
    }
  }

  /**
   * Handle is any checkbox is check
   */
  hanldeSelectAnyItem(isSelect: boolean) {
    this.isSelectAnyItem = isSelect
  }

  /**
   * Handle move topic from unclassified to selected folder
   * Call API move to selected folder
   */
  handleMoveToUnclassified(isFromBin: boolean) {
    if (this.roleEditTopic && this.isSelectAnyItem) {
      this.$blockui.show()
      GroupTopicService.moveTopicsToUnclassified(
        this.$route.params.groupId,
        this.getSelectedTopicId(),
        isFromBin ? MoveToUnclsFrom.BIN : MoveToUnclsFrom.FOLDER
      )
        .then(res => {
          if (res.status === 200) {
            const newList = this.topics.filter(
              file => !this.getSelectedTopicId().includes(file.id)
            )
            this.topics = [...newList]
            this.isSelectAnyItem = false
            this.checkAll = false
            this.initFolder()
          }
        })
        .catch(error => {
          this.modalMess = this.$t('common.message.file_not_exist') as string
          this.$bvModal.show('modal-error')
          this.initFolder()
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Handle move topic from unclassified to selected folder
   * Call API move to selected folder
   */
  handleMoveToFolder(selectedFolder: TopicFolder) {
    if (this.roleEditTopic && this.isSelectAnyItem) {
      this.$blockui.show()
      GroupTopicService.moveTopicsToFolder({
        group_id: this.$route.params.groupId,
        topic_ids: this.getSelectedTopicId(),
        folder_id: selectedFolder.id
      })
        .then(res => {
          if (res.status === 200) {
            if (this.folderSelected.value !== 0) {
              const newList = this.topics.filter(
                file => !this.getSelectedTopicId().includes(file.id)
              )
              this.topics = [...newList]
            } else {
              this.topics.forEach(item => (item.checked = false))
            }
            this.isSelectAnyItem = false
            this.checkAll = false
            this.initFolder()
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            if (err.response.data.message === 'topic_not_found') {
              this.modalMess = this.$t(
                'common.message.topic_not_exist'
              ) as string
              this.allTopicNotDelete()
            } else if (err.response.data.message === 'folder_not_exist') {
              this.modalMess = this.$t(
                'common.message.folder_not_exist'
              ) as string
              this.initFolder()
            } else {
              this.modalMess = this.$t('common.message.uncatch_error') as string
            }
          } else {
            this.modalMess = this.$t('common.message.uncatch_error') as string
          }
          this.$bvModal.show('modal-error')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Jump to create new topic page
   */
  jumpToCreateTopicPage() {
    if (this.isCommonTopic) {
      this.$router.push({ name: 'common-topic-new-feed-topic-create' })
    } else {
      this.$router.push({ name: 'group-new-feed-topic-create' })
    }
  }

  routerCreateTopicPage() {    
    if (this.isCommonTopic) {
      return { name: 'common-topic-new-feed-topic-create' }
    } else {
      return { name: 'group-new-feed-topic-create' }
    }
  }

  /**
   * Handle select sort
   */
  handleSortChange(index: number) {
    this.sortSelected = this.sortOptions[index]
  }

  /**
   * Handle delete topics
   * Call API delete topics
   */
  handleMoveTopicToBin() {
    if (this.roleEditTopic && this.isSelectAnyItem) {
      this.$blockui.show()
      GroupTopicService.moveTopicsToBin(
        this.$route.params.groupId,
        this.getSelectedTopicId()
      )
        .then(res => {
          if (res.status === 200) {
            const newList = this.topics.filter(
              file => !this.getSelectedTopicId().includes(file.id)
            )
            this.topics = [...newList]
            this.isSelectAnyItem = false
            this.checkAll = false
            this.modalMess = this.$t('common.message.deleted') as string
            this.$bvModal.show('modal-success')
            this.initFolder()
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.modalMess = this.$t('common.message.topic_not_exist') as string
            this.allTopicDeleted()
          } else {
            this.modalMess = this.$t('common.message.delete_fail') as string
          }
          this.$bvModal.show('modal-error')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * open modal confirm for delete topics in bin
   */
  openConfirmDeleteBin(isEmptyBin: boolean) {
    if (isEmptyBin) {
      this.modalMess = this.$t('common.message.delete_all') as string
    } else {
      this.modalMess = this.$t('common.message.delete') as string
    }
    this.isDeleteAllBin = isEmptyBin
    this.$bvModal.show('modal-confirm')
  }

  /**
   * Handle delete topics in bin
   *
   * if (isDeleteAllBin === true)
   * Call API empty bin
   *
   * else
   * Call API delete topics in bin
   *
   */
  handleDeleteBin() {
    if (this.roleEditTopic) {
      this.$blockui.show()
      if (this.isDeleteAllBin) {
        GroupTopicService.emptyBin(Number(this.$route.params.groupId))
          .then(res => {
            if (res.status === 200) {
              this.topics = []
              this.modalMess = this.$t('common.message.deleted') as string
              this.$bvModal.show('modal-success')
              this.initFolder()
            }
          })
          .catch(() => {
            this.modalMess = this.$t('common.message.delete_fail') as string
            this.$bvModal.show('modal-error')
          })
          .finally(() => this.$blockui.hide())
      } else {
        GroupTopicService.deleteTopicsInBin(
          this.$route.params.groupId,
          this.getSelectedTopicId()
        )
          .then(res => {
            if (res.status === 200) {
              const newList = this.topics.filter(
                file => !this.getSelectedTopicId().includes(file.id)
              )
              this.topics = [...newList]
              this.isSelectAnyItem = false
              this.checkAll = false

              this.modalMess = this.$t('common.message.deleted') as string
              this.$bvModal.show('modal-success')
              this.initFolder()
            }
          })
          .catch(() => {
            this.modalMess = this.$t('common.message.delete_fail') as string
            this.$bvModal.show('modal-error')
          })
          .finally(() => this.$blockui.hide())
      }
    }
  }

  /**
   * Handle create new folder
   * Call API create new folder
   */
  handleAddFolder(newName: string) {
    if (this.roleEditTopic) {
      this.$blockui.show()
      GroupTopicService.addNewFolder(this.$route.params.groupId, newName)
        .then(res => {
          if (res.status === 201) {
            this.initFolder()
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.$bvModal.show('modal-error')
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Handle rename selected folder
   * Call API rename selected folder
   */
  handleRenameFolder(newName: string) {
    if (this.roleEditTopic) {
      const folderId = this.folderEdit.value.id
      this.$blockui.show()
      GroupService.renameFolder({
        group_id: this.$route.params.groupId,
        id: folderId,
        folder_name: newName
      })
        .then(res => {
          if (res.status === 200) {
            if (this.folderEdit.value.id === this.folderSelected.value.id) {
              this.title = newName
              this.folderSelected.text = newName
              this.folderSelected.value.name = newName
            } else {
              const editFolder = this.folderOptions.find(
                item => item.id === this.folderEdit.id
              )
              if (editFolder) {
                editFolder.text = newName
                editFolder.value.name = newName
              }
            }
          }
        })
        .catch(err => {
          if (
            err.response.status === 404 &&
            err.response.data.message === 'folder_not_found'
          ) {
            this.modalMess = this.$t(
              'common.message.folder_not_exist'
            ) as string
            this.$bvModal.show('modal-error')
          }
        })
        .finally(() => this.$blockui.hide())
    }
  }

  /**
   * Handle delete selected folder
   * Call API delete selected folder
   */
  handleDeleteFolder() {
    if (this.roleEditTopic) {
      this.$blockui.show()
      GroupService.deleteFolder({
        group_id: this.$route.params.groupId,
        folder_id: this.folderEdit.value.id,
        type: EFolderType.TOPIC
      })
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.deleted') as string
            this.$bvModal.show('modal-success')
            this.initFolder()
          }
        })
        .catch(err => {
          if (
            err.response.status === 404 &&
            err.response.data.message === 'folder_not_found'
          ) {
            this.modalMess = this.$t(
              'common.message.folder_not_exist'
            ) as string
            this.$bvModal.show('modal-error')
          }
        })
        .finally(() => {
          this.initFolder()
          this.$blockui.hide()
        })
    }
  }

  /**
   * Handle select folder
   */
  handleSelectFolder(index: number) {
    this.folderSelected = this.folderOptions[index]
  }

  /**
   * Get selected topics
   */
  getSelectedTopicId(): number[] {
    return this.topics.filter(item => item.checked).map(item => item.id)
  }
}

export class TopicCompactViewModel extends TopicCompact {
  checked: boolean = false
  nickname: string = ''
  name_company: string = ''
  status_company: string = ''
  status_company_create: string = ''
  name: string = ''
  path: string = ''
  groups: any = []
  file_common_topics: any = []

  constructor(init: TopicCompact) {
    super()
    Object.assign(this, new TopicCompact(init))
  }
}

export interface IDropDown {
  id: number
  value: any
  text: string
  suffix?: string
}

export enum EState {
  ALL, // UNCLASSIFIED also = ALL
  FOLDER,
  BIN,
  DRAFT
}
