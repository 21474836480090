

































































import { Component, Prop, Vue } from "vue-property-decorator";
import { IDropDown } from "./NewFeedLayout.vue";
import NavMenuBar from "@/components/NavMenuBar.vue";

@Component({ components: { NavMenuBar } })
export default class SidebarPc extends Vue {
  @Prop() private roleEditTopic!: boolean;
  @Prop() private folderSelected!: any;
  @Prop() private folderOptions!: IDropDown[];

  genRoute(folder: any) {
    const getFolder = () => {
      switch (folder.id) {
        case 0:
          return "all";
        case 1:
          return "unclassified";
        case 2:
          return "bin";
        case 3:
          return "draft";
        default:
          return folder.value.id;
      }
    };

    this.$route.query.folder;
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: { folder: getFolder() },
    };
  }

  handleSelectFolder(event: any, index: number) {
    if (!event.ctrlKey) {
      this.$emit("handleSelectFolder", index);
    }
  }

  openEditFolderModal(index: number) {
    this.$emit("openEditFolderModal", index);
  }
}
